import React from 'react';
import Header from '../../components/header/header.component';
import TodoList from '../../components/projects/todoList/todoList.component';

const Project2 = () => {
    return (
        <>
        <Header/>
        <TodoList/>
        </>
    )
}

export default Project2;