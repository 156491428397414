import React from 'react';
import SoftSkills from "../../components/softSkills/softSkills.component"
import Header from '../../components/header/header.component';

const SoftSkillsRoute = () => {
    return (
        <>
            <Header />
            < SoftSkills />
        </>

    )
}

export default SoftSkillsRoute;