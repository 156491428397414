import React from 'react';
import './softSkilss.style.css';
import { ADAPTABILITY, ADAPTABILITY_TEXT, CONTINUOUS_LEARNING, CONTINUOUS_LEARNING_TEXT, CREATIVITY, CREATIVITY_TEXT, EFFECTIVE_COMMUNICATION, EFFECTIVE_COMMUNICATION_TEXT, PROBLEM_SOLVING, PROBLEM_SOLVING_TEXT, SOFT_SKILLS_TAG, TEAMWORK, TEAMWORK_TEXT } from '../../helper/texts/variables';

const SoftSkills = () => {
    return (
        <div id='mainContent'>
            <h2 className='softSkillsTag'>{SOFT_SKILLS_TAG}</h2>
            <div className="soft-skills-list">
                <div className="soft-skill">
                    <span className="skill-name">{EFFECTIVE_COMMUNICATION}</span><br />
                    <span className="skill-description">{EFFECTIVE_COMMUNICATION_TEXT}</span>
                </div>
                <div className="soft-skill">
                    <span className="skill-name">{TEAMWORK}</span><br />
                    <span className="skill-description">{TEAMWORK_TEXT}</span>
                </div>
                <div className="soft-skill">
                    <span className="skill-name">{PROBLEM_SOLVING}</span><br />
                    <span className="skill-description">{PROBLEM_SOLVING_TEXT}</span>
                </div>
                <div className="soft-skill">
                    <span className="skill-name">{CREATIVITY}</span><br />
                    <span className="skill-description">{CREATIVITY_TEXT}</span>
                </div>
                <div className="soft-skill">
                    <span className="skill-name">{CONTINUOUS_LEARNING}</span><br />
                    <span className="skill-description">{CONTINUOUS_LEARNING_TEXT}</span>
                </div>
                <div className="soft-skill">
                    <span className="skill-name">{ADAPTABILITY}</span><br />
                    <span className="skill-description">{ADAPTABILITY_TEXT}</span>
                </div>
                {/* Adicione mais habilidades aqui conforme necessário */}
            </div>
        </div>
    );
};

export default SoftSkills;
