import React from "react";
import { Link } from "react-router-dom";
import './componentsList.style.css'

const ComponentsList = () => {
  return (
    <div id="container">
      <h1>Projects</h1>
      <ul className="lista-links">
        <li>
          <Link to="/tictactoe" className="link">Tic Tac Toe</Link>
        </li>
        <li>
          <Link to="/todolist" className="link">Todo List</Link>
        </li>
        <li>
        <Link to="/cats" className="link">Cards of cats</Link>
      </li>
      </ul>
    </div>
  );
};

export default ComponentsList;
