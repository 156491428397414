import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Home from "./routes/home/home.component";
import SkillsRoute from "./routes/skills/skillsRoute.component";
import SoftSkillsRoute from "./routes/softSkills/softSkillsRoute.component"
import NotFound from "./components/notFound/notFound.component";
import Project1 from "./routes/project1/project1.component";
import Project2 from "./routes/project2/project2.component";
import ProjectsList from "./routes/projectsList/projectsList.component";
import ProjectCats from "./routes/catsDisplayCards/catsDisplayCards.component.jsx"

const AppContainer = styled.div`
  width: 100%;
  max-width: 960px; /* Defina a largura máxima desejada */
  margin: 0 auto; /* Centralize o contêiner na página */
  padding: 0 20px; /* Adicione algum espaçamento opcional nas laterais */

  @media (max-width: 768px) { /* Define estilos para dispositivos com largura máxima de 768px (tablets e dispositivos móveis) */
    max-width: 80%; /* Define a largura máxima para preencher a largura da tela */
    padding: 0 10px; /* Reduz o preenchimento nas laterais */
  }
`;

const App = () => {
  return (
    <AppContainer>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/skills" element={<SkillsRoute />} />
        <Route path="/softskills" element={<SoftSkillsRoute />} />
        <Route path="/projects" element={<ProjectsList />} />
        <Route path="/tictactoe" element={<Project1 />} />
        <Route path="todolist" element={<Project2 />} />
        <Route path="/cats" element={<ProjectCats />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </AppContainer>
  );
};

export default App;
