import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Hearts } from "react-loader-spinner";

const CatGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CatCard = styled.div`
  width: 300px;
  margin: 20px;
  padding: 20px;
  border: none; /* Remova a borda */
  border-radius: 8px;
  background-color: #fff; /* Cor de fundo cinza claro */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra */
  transition: all 0.3s ease;
  transform-style: preserve-3d; /* Mantém a perspectiva 3D */
  perspective: 1000px; /* Define a perspectiva para o efeito 3D */

  position: relative; /* Para garantir que o conteúdo adicional fique atrás */
  cursor: pointer; /* Altera o cursor para indicar interatividade */
  transform-style: preserve-3d; /* Mantém a perspectiva 3D */
  transition: transform 0.3s ease; /* Adiciona uma transição suave */
  
  &:hover {
    transform: rotateY(180deg); /* Rotação 3D no hover */
  }
`;
const CatImage = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 8px;
  border: 3px solid black;
`;

const CatInfo = styled.div`
  margin-top: 15px;
  color: black;
  position: absolute; /* Posicionamento absoluto para sobrepor o conteúdo */
  top: 0;
  left: 0;
  width: 90%;
  height: 80%;
  backface-visibility: hidden; /* Oculta a face traseira do elemento */
  transform: rotateY(180deg); /* Inicialmente, oculta a face traseira */
  background-color: #fff;
  border-radius: 8px;
  padding: 5%;
`;

const CatTitle = styled.h2`
  margin-top: 0;
  font-size: 1.5rem;
  color: black;
`;

const CatLink = styled.a`
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px; /* Adiciona espaçamento interno ao botão */
  text-align: center;
  color: black;
  background-color: #7ED957; /* Cor de fundo do botão */
  border: none;
  border-radius: 5px; /* Borda arredondada */
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease; /* Transição suave na cor de fundo */

  /* Centraliza horizontalmente */
  margin-left: auto;
  margin-right: auto;

  /* Centraliza verticalmente */
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: green; /* Altera a cor de fundo no hover */
    color: #fff
  }
`;

const LoadingIndicator = styled.div`
  width: 100%;
  height: 100vh; /* Altura total da tela */
  display: flex;
  justify-content: center; /* Alinhar horizontalmente */
  font-size: 1.2rem;
  color: #777;
`;


const DisplayCatsCards = () => {
  const [catsData, setCatsData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (loading || !hasMore) return;

      setLoading(true);

      try {
        const breeds = await axios.get(
          `https://api.thecatapi.com/v1/breeds?limit=10&page=${page}`
        );

        if (breeds.data.length === 0) {
          setHasMore(false);
          setLoading(false);
          return;
        }

        const fetchedCatsData = [];

        for (const id of breeds.data) {
          if (id.reference_image_id) {
            const imgId = id.reference_image_id;
            const catDataResponse = await axios.get(
              `https://api.thecatapi.com/v1/images/${imgId}`
            );
            fetchedCatsData.push(catDataResponse.data);
          }
        }

        setCatsData((prevCatsData) => [...prevCatsData, ...fetchedCatsData]);
        setPage((prevPage) => prevPage + 1);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const observer = new IntersectionObserver(
      async (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          fetchData();
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(document.getElementById("bottom-of-list"));

    return () => {
      observer.disconnect();
    };
  }, [loading, page, hasMore]);

  return (
    <>
      <CatGallery>
        {catsData.map((cat, index) => (
          <CatCard key={cat.id}>
            <CatImage src={cat.url} alt="Cat" />
            <CatTitle>{cat.breeds[0].name}</CatTitle>
            <CatInfo>
              <p>
                <strong>Child Friendly:</strong> {cat.breeds[0].child_friendly}
              </p>
              <p>
                <strong>Temperament:</strong> {cat.breeds[0].temperament}
              </p>
              <p>
                <strong>Origin:</strong> {cat.breeds[0].origin}
              </p>
              <p>
                <strong>Health Issue:</strong> {cat.breeds[0].health_issues}
              </p>
              <p>
                <strong>Life expectancy:</strong> {cat.breeds[0].life_span}
              </p>
              <CatLink
                href={cat.breeds[0].wikipedia_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                More Info
              </CatLink>
            </CatInfo>
          </CatCard>
        ))}
        
      </CatGallery>
      {loading && (
        <LoadingIndicator>
          <Hearts type="Hearts" color="#7ED957" height={100} width={100} />
        </LoadingIndicator>
      )}
      <div id="bottom-of-list" style={{ height: "10px" }} />
    </>
  );
};

export default DisplayCatsCards;
