import React from "react";
import styled from "styled-components";
import { SKILLS_TAG } from "../../helper/texts/variables";

const mySkills = [
  { name: "JavaScript", proficiency: 5 },
  { name: "React", proficiency: 5 },
  { name: "Redux", proficiency: 5 },
  { name: "HTML", proficiency: 5 },
  { name: "CSS", proficiency: 5 },
  { name: "GitHub", proficiency: 5 },
  { name: "Typescript", proficiency: 4 },
  { name: "TailwindCSS", proficiency: 4 },
  { name: "Firebase", proficiency: 3 },
  { name: "Storybooks", proficiency: 3 },
  { name: "Nextjs", proficiency: 2 },
  { name: "React Native", proficiency: 2 },
];

const MainContent = styled.div`
  padding-top: 100px;
  padding-bottom: 57px;
  width: 80%;
  margin: 0 auto;
`;

const SkillsTag = styled.h2`
  color: white;
  font-weight: 400;
  color: #7ed957;
`;

const ProfileHeader = styled.section`
  /* Adicione os estilos necessários */
`;

const SkillsContainer = styled.div`
  /* Adicione os estilos necessários */
`;

const SkillsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Skill = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
  flex: 1 0 200px;
  max-width: 150px;

`;

const StyledStar = styled.span`
  color: #7ed957;
`;

const StarRating = ({ proficiency }) => {
  const stars = [1, 2, 3, 4, 5]; // Número total de estrelas
  return (
    <div>
      {stars.map((star, index) => (
        <StyledStar key={index}>{index < proficiency ? "★" : "☆"}</StyledStar>
      ))}
    </div>
  );
};

const Skills = () => {
  return (
    <MainContent>
      <SkillsTag>{SKILLS_TAG}</SkillsTag>
      <ProfileHeader>
        <SkillsContainer>
          <SkillsList>
            {mySkills.map((skill, index) => (
              <Skill key={index}>
                <h3>{skill.name}</h3>
                <StarRating proficiency={skill.proficiency} />
              </Skill>
            ))}
          </SkillsList>
        </SkillsContainer>
      </ProfileHeader>
    </MainContent>
  );
};

export default Skills;
