import React from 'react';
import "./resume.style.css"
import logo2 from "../../assets/profileLogo/photo-6.jpg"
import linkedinLogo from "../../assets/socialLogo/linkedin-logo.png"
import githubLogo from "../../assets/socialLogo/githubLogo.png"
import mediumLogo from "../../assets/socialLogo/medium-logo2.png"
import { ABOUT_TAG, NAME, RESUME, RESUME_TITLE } from "../../helper/texts/variables"
import { Link } from 'react-router-dom';




const Resume = () => {
    return (
        <div id='mainContent'>
            <h2 className='aboutTag'>{ABOUT_TAG}</h2>
            <div class="profilePhoto">
                {/* <!-- Profile photo --> */}
                <img src={logo2} alt="sample" />
            </div>
            {/* <!-- Identity details --> */}
            <section class="profileHeader">
                <h1>{NAME.toUpperCase()}</h1>
                <h3>{RESUME_TITLE.toUpperCase()}</h3>
                <hr />
                <p>
                    {RESUME}
                </p>
            </section>
            {/* <!-- Links to Social network accounts --> */}
            <aside class="socialNetworkNavBar">
                <div class="socialNetworkNav">
                    {/* <!-- Add a Anchor tag with nested img tag here --> */}
                    <Link to="https://www.linkedin.com/in/cardosonatiene" target='_blank' ><img src={linkedinLogo} alt="sample" /></Link>
                </div>
                <div class="socialNetworkNav">
                    {/* <!-- Add a Anchor tag with nested img tag here --> */}
                    <Link to="https://github.com/Natiene" target='_blank'><img src={githubLogo} alt="sample" /></Link>
                </div>
                <div class="socialNetworkNav">
                    {/* <!-- Add a Anchor tag with nested img tag here --> */}
                    <Link to="https://medium.com/@natienecardoso" target='_blank'><img src={mediumLogo} alt="sample" /></Link>
                </div>

            </aside>
        </div>

    )
}

export default Resume;