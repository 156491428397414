import React from 'react';
import Resume from "../../components/resume/resume.component"
import Header from '../../components/header/header.component';

const Home = () => {
    return (
        <>
            <Header />
            <Resume />
        </>

    )
}

export default Home;