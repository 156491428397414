/**
 * About
 */

export const NAME = "Natiene"

export const RESUME = "I'm a software developer with six years of experience. I've done a bit of everything, from building full websites to focusing on making them look good and work smoothly on the frontend. My diverse skill set, dedication to quality, and eagerness to learn make me the perfect fit for your team. I'm ready to contribute my expertise and help drive our projects to success."

export const RESUME_TITLE = "REALLY AWESOME FRONTEND ENGINEER"

export const ABOUT_TAG = "<about/>"

/**
 * Skills
 */

export const SKILLS_TAG = "<skills/>"

export const SKILLS_TITLE = "Skills"

/**
 * Softs Skills
 */

export const SOFT_SKILLS_TAG = "<softskills/>"

export const EFFECTIVE_COMMUNICATION= "Effective Communication"

export const EFFECTIVE_COMMUNICATION_TEXT= "Comunicating clearly and concisely with team members and stakeholders"

export const TEAMWORK = "Teamwork"

export const TEAMWORK_TEXT = "Collaborating harmoniously with others to achieve project goals"

export const PROBLEM_SOLVING = "Problem-solving"

export const PROBLEM_SOLVING_TEXT = " Identifying and resolving issues efficiently and creatively"

export const ADAPTABILITY = "Adaptability"

export const ADAPTABILITY_TEXT = "Adapting to changing project requirements and emerging technologies"

export const CONTINUOUS_LEARNING = "Continuous Learning"

export const CONTINUOUS_LEARNING_TEXT = "Being open to acquiring new skills and knowledge to improve development capabilities"

export const CREATIVITY = "Creativity"

export const CREATIVITY_TEXT = "Thinking creatively to develop innovative solutions and designs"

/**
 * Tic Tac Toe project
 */

export const NEXT_PLAYER = "Next Player"
export const IT_IS_A_DRAW = "It's a draw!"
export const WINNER = "Winner"
export const RESET_GAME = "Reset Game"





