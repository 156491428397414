import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import logo1 from "../../assets/profileLogo/nati.png";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding: 10px 20px;

  @media screen and (min-width: 769px) {
    .menuList {
      display: flex;
      gap: 10px;
    }

    .menuItem {
      text-decoration: none;
      color: #fff;
      font-weight: bold;
      padding: 10px 20px;
      border: 1px solid #fff;
      border-radius: 10px;
      transition: background-color 0.3s, transform 0.2s;
    }

    .menuItem:hover {
      background-color: #7ed957;
      border-color: #7ed957;
      color: #000;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row; /* Altera a direção do flex para coluna */
    padding: 10px; /* Ajusta o espaçamento */
    .menuList {
      position: absolute;
      top: calc(10% + 5px);
      z-index: 1;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 10px;
      display: none;
      flex-direction: column;
      width: 100px;
    }

    .menuList.show {
      display: flex;
    }

    .menuItem {
      text-decoration: none;
      color: #333;
      padding: 5px 10px;
      transition: background-color 0.3s;
      border-bottom: 1px solid black;
    }

    .menuItem:hover {
      background-color: #f0f0f0;
    }

    .toggleButton {
      display: block;
    }

    .menuLinks {
      display: none;
    }

    .menuContainer {
      position: relative;
      width: auto;
    }
  }

  @media screen and (min-width: 769px) {
    .toggleButton {
      display: none;
    }

    .menuLinks {
      display: flex;
    }
  }
`;

const ToggleButton = styled.div`
  font-size: 22px; /* Ajuste o tamanho do ícone conforme desejado */
  padding: 10px;
  color: #007bff;
  cursor: pointer;
`;

const ProfileLogo = styled.div`
  img {
    width: 100px;
    height: 50px;
    border-radius: 80%;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const MenuList = styled.div`
  position: fixed;
`;

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <HeaderContainer>
      <ProfileLogo>
        <Link to="/">
          <img src={logo1} alt="sample logo" />
        </Link>
      </ProfileLogo>
      <MenuContainer>
        <ToggleButton className="toggleButton" onClick={toggleMenu}>
          <FaBars color="#fff" />
        </ToggleButton>
        <MenuList className={`menuList ${showMenu ? "show" : ""}`}>
          <a href="/" className="menuItem">
            About
          </a>
          <a href="/skills" className="menuItem">
            Skills
          </a>
          <a href="/softskills" className="menuItem">
            Soft Skills
          </a>
          <a href="projects" className="menuItem">
            Projects
          </a>
        </MenuList>
      </MenuContainer>
    </HeaderContainer>
  );
};

export default Header;
