import React from "react";
import ComponentsList from "../../components/componentsList/componentsList.component";
import Header from "../../components/header/header.component";

const ProjectsList = () => {
  return (
    <>
      <Header />
      <ComponentsList />
    </>
  );
};

export default ProjectsList;
