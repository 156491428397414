import React from "react";
import TicTacToe from "../../components/projects/jogoDaVelha/ticTacToe.component";
import Header from "../../components/header/header.component";

const Project1 = () => {
  return (
    <>
      <Header />
      <TicTacToe />
    </>
  );
};

export default Project1;
