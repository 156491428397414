import React from 'react';
import Confetti from 'react-confetti';

function ConfettiEffect() {
  return (
    <Confetti
      width={window.innerWidth}
      height={window.innerHeight}
      numberOfPieces={400}
      recycle={false}
      gravity={0.2}
    />
  );
}

export default ConfettiEffect;
