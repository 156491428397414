import React from 'react';
import Skills from "../../components/skills/skills.component"
import Header from '../../components/header/header.component';

const SkillsRoute = () => {
    return (
        <>
            <Header />
            <Skills />
        </>

    )
}

export default SkillsRoute;